import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import { ScheduleAPI } from '~/service/schedule'
import marketingPreferences from '../service/marketing'
import ScheduleSummary from '../components/ScheduleSummary'
import { PaymentBreakdown } from '~/components/PaymentBreakdown'
import MarketingPreferences from '../components/MarketingPreferences'
import { Header } from '~/components/Header'
import AdjustedPaymentBreakdown from "../components/AdjustedPayment";
import { ScheduleOptionsCard } from "~/components/ScheduleOptionsCard";

const copy = (p) => JSON.parse(JSON.stringify(p))

const MarketingPreferencesWrapper = ({ donorId, email, activeCharity }) => {
  const [error, setError] = useState('')
  const [preferences, setPreferences] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const p = await marketingPreferences.read(donorId)
        setPreferences(p)
      } catch (e) {
        setError(`Error loading marketing preferences: ${e.message}`)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [donorId, activeCharity])

  const updatePreference = async (template) => {
    try {
      setError('')
      const res = await marketingPreferences.setPreference(donorId, template)
      const p = copy(preferences)
      if (template.transport === 'email') {
        p.email = res
      } else {
        p[template.transport][template.identifier] = res
      }
      setPreferences(p)
    } catch (e) {
      setError(`Error setting charity preference: ${e.message}`)
    }
  }

  if (loading || !preferences) {
    return <Card className="my-3 rounded shadow-lg"><Card.Header>Loading...</Card.Header></Card>
  }

  return <MarketingPreferences
    preferences={preferences}
    email={email}
    activeCharity={activeCharity}
    error={error}
    updatePreference={updatePreference}
  />
}

const ScheduleDetails = ({ donorId, scheduleId }) => {
  const [error, setError] = useState('')
  const [schedule, setSchedule] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const s = await ScheduleAPI.read(donorId, scheduleId)
        setSchedule(s)
      } catch (e) {
        setError(`Error loading schedule: ${e.message}`)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [donorId, scheduleId])

  if (error && !schedule) {
    return <Container><Alert variant="danger" className="my-3">{error}</Alert></Container>
  }

  if (loading || !schedule) {
    return <Container><h2>Loading...</h2></Container>
  }

  const refund = async (index) => {
    try {
      setError('')
      const s = await ScheduleAPI.refund(donorId, scheduleId, index)
      alert('Refund has been requested. Please note it can take 5-10 days to appear')
      setSchedule(s)
    } catch (e) {
      setError(`Error requesting refund: ${e.message}`)
    }
  }

  const toggleCancellation = async () => {
    try {
      setError('')
      const s = await ScheduleAPI.setCancelled(donorId, scheduleId, !schedule.cancelled)
      setSchedule(s)
    } catch (e) {
      setError(`Error changing schedule cancelled status: ${e.message}`)
    }
  }

  const toggleNight30 = async () => {
    try {
      setError('')
      const s = await ScheduleAPI.setNight30(donorId, scheduleId, !schedule.night_30)
      setSchedule(s)
    } catch (e) {
      setError(`Error changing night 30 preference: ${e.message}`)
    }
  }

  const toggleAutoEnrollStatus = async () => {
    try {
      setError('')
      const s = await ScheduleAPI.updateAutoEnroll(donorId, scheduleId, !schedule.auto_enroll)
      setSchedule(s)
    } catch (e) {
      setError(`Error changing auto enroll preference: ${e.message}`)
    }
  }

  const resendConfirmation = async () => {
    if (!window.confirm('Are you sure you wish to resend the schedule confirmation email?')) {
      return
    }
    try {
      setError('')
      await ScheduleAPI.resendConfirmation(donorId, scheduleId)
      alert('Confirmation email has been resent')
    } catch (e) {
      setError(`Error resending confirmation email: ${e.message}`)
    }
  }

  const resendReceipt = async (index) => {
    if (!window.confirm(`Are you sure you wish to resend the email receipt for index ${index}?`)) {
      return
    }
    try {
      setError('')
      await ScheduleAPI.resendReceipt(donorId, scheduleId, index)
      alert('Nightly receipt email has been resent for index ' + index)
    } catch (e) {
      setError(`Error resending receipt: ${e.mesage}`)
    }
  }

  return <>
    <Container>
      {error && <Alert className="my-3" variant="danger">{error}</Alert>}
      <Row>
        <Col xs={12} md={8}>
          <ScheduleSummary schedule={schedule} donorId={donorId} />
          <PaymentBreakdown
            onRefund={refund}
            onResendReceipt={resendReceipt}
            schedule={schedule} />
          {schedule.adjusted_payments && Object.keys(schedule.adjusted_payments).length && <AdjustedPaymentBreakdown
            schedule={schedule}
            onRefund={refund}
            onResendReceipt={resendReceipt} />}
          <MarketingPreferencesWrapper
            donorId={donorId}
            email={schedule.donor_details.email}
            activeCharity={schedule.charity} />
        </Col>
        <Col xs={12} md={4}>
          <ScheduleOptionsCard
            donorId={donorId}
            schedule={schedule}
            scheduleId={scheduleId}
            toggleCancellation={toggleCancellation}
            resendConfirmation={resendConfirmation}
            toggleNight30={toggleNight30}
            toggleAutoEnrollStatus={toggleAutoEnrollStatus} />
        </Col>
      </Row>
    </Container>
  </>
}

export const Schedule = () => {
  const { scheduleId, donorId } = useParams()
  return <>
    <Header scheduleId={scheduleId} />
    <ScheduleDetails donorId={donorId} scheduleId={scheduleId} />
  </>
}
