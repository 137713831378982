import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'
import { Breadcrumb, Nav, Navbar } from 'react-bootstrap'

import { useAuth } from '@lib/hooks'

const PageHeader = ({ email, onHome, onLogout, scheduleId, donationID, item }) => {
  const logout = () => {
    // Record breadcrumb
    Sentry.addBreadcrumb({
      category: 'auth.logout',
      message: 'Logged out user ' + email,
      level: 'info'
    })

    // Logout logic
    onLogout()
    onHome()

    // Event enriching
    Sentry.setUser(null)
  }

  return <>
    <Navbar bg="light" expand="lg">
      <Navbar.Brand>Customer Support</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link onClick={logout}>
            {email}{' '}
            <i
              className="small"
            >
              (log out)
            </i>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <Breadcrumb>
      <Breadcrumb.Item
        onClick={onHome}
        style={{ cursor: 'pointer' }}
        active
      >
        {item}
      </Breadcrumb.Item>
      {scheduleId && <Breadcrumb.Item active>{scheduleId}</Breadcrumb.Item>}
    </Breadcrumb>
  </>
}

export const Header = ({ scheduleId, item = 'Schedule' }) => {
  const history = useHistory()
  const onHome = () => history.push('/')
  const { user, logout } = useAuth()

  // Identify impacted user
  if (user !== null) {
    // Record breadcrumb
    Sentry.addBreadcrumb({
      category: 'auth.login',
      message: 'Authenticated user ' + user.email,
      level: 'info'
    })

    // Event enriching
    // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
    Sentry.setUser({ email: user.email })
  }

  return <PageHeader email={user?.email} item={item} onHome={onHome} onLogout={logout} scheduleId={scheduleId} />
}
